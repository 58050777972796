.blog-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
  }
  
  .blog-title {
    text-align: center;
    font-size: 2.5rem;
    color: #1e2a3a;
    margin-bottom: 2rem;
  }
  
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .blog-post-link {
    flex-basis: calc(50% - 1rem);
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-post {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post-link:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .blog-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-post-content {
    padding: 1.2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center; /* Center all content horizontally */
    text-align: left; /* Ensure text is left-aligned */
  }
  
  .blog-post h2 {
    margin-top: 0;
    font-size: 1.3rem;
    color: #2c3e50;
    align-self: flex-start; /* Align title to the left */
    width: 100%; /* Ensure full width */
  }
  
  .blog-post-date {
    font-size: 0.8rem;
    color: #7f8c8d;
    margin-bottom: 0.8rem;
    align-self: flex-start; /* Align date to the left */
    width: 100%; /* Ensure full width */
  }
  
  .blog-post-extract {
    flex-grow: 1;
    margin-bottom: 0.8rem;
    font-size: 0.9rem;
    line-height: 1.4;
    align-self: flex-start; /* Align extract to the left */
    width: 100%; /* Ensure full width */
  }
  
  .read-more-link {
    align-self: center;
    color: #3498db;
    font-size: 0.9rem;
    text-decoration: none;
    border-bottom: 1px solid #3498db;
    transition: color 0.3s ease, border-bottom-color 0.3s ease;
    position: relative;
    z-index: 1;
    margin-top: auto; /* Push the link to the bottom */
  }
  
  .read-more-link:hover {
    color: #2980b9;
    border-bottom-color: #2980b9;
  }

@media (max-width: 768px) {
  .blog-list {
    flex-direction: column;
    gap: 1.5rem;
  }

  .blog-post-link {
    flex-basis: 100%;
  }

  .blog-post-content {
    padding: 1rem;
  }

  .blog-post h2 {
    font-size: 1.2rem;
  }

  .blog-post-extract {
    font-size: 0.85rem;
  }

  .read-more-link {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .blog-title {
    font-size: 1.8rem;
  }

  .blog-intro {
    font-size: 0.9rem;
  }

  .blog-post-image {
    height: 150px;
  }

  .blog-post-content {
    padding: 0.8rem;
  }
}