.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .logo-link {
    text-decoration: none;
    color: inherit;
  }
  
  .logo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .logo img {
    width: 40px;
    height: auto;
  }
  
  .logo span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1e2a3a;
  }
  
  .main-nav ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .main-nav li {
    margin-left: 2rem;
  }
  
  .main-nav a {
    text-decoration: none;
    color: #1e2a3a;
    font-weight: 500;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .main-nav a:hover {
    color: #3498db;
  }