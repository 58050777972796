body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #fff1eb 0%, #ace0f9 100%);
  min-height: 100vh;
  color: #333;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.landing-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.intro-section, .virtual-try-on-section, .api-example-section {
  width: 100%;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 3.4rem; /* Increased to 3.4rem */
  color: #1e2a3a;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  font-size: 1.2rem;
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.intro-section {
  width: 100%;
  text-align: center;
  max-width: 800px;
  margin: 2rem auto 0; /* This adds top margin to the entire section */
}

.intro-section h1 {
  font-family: 'Poppins', sans-serif;
  color: #1e2a3a;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.intro-section p {
  color: #4a4a4a;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.use-cases {
  margin-top: 0.5rem; /* Reduced from 1rem to bring it closer to the top */
  margin-bottom: 1rem;
  text-align: center; /* Center the use cases */
  width: 100%; /* Ensure it takes full width of its container */
  display: inline-block; /* This will help center the text within use-cases */
}

.use-case {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  color: #4a4a4a;
  line-height: 1.4;
  font-weight: 500; /* Makes the text semi-bold */
}

.use-case:last-child {
  margin-bottom: 0;
}

.cta-buttons {
  margin-top: 2rem;
  
}

.primary-button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.2rem; /* Increased from 1rem */
  background-color: #3498db;
  color: white;
  border: none;
  padding: 1rem 2rem; /* Increased from 0.75rem 1.5rem */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 6px 12px rgba(0,0,0,0.4)
}

.primary-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
  /* box-shadow: 0px 6px 12px rgba(0,0,0,0.1) */
}

.virtual-try-on-section {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: transparent; /* Ensure the background is transparent */
}

.virtual-try-on {
  max-width: 800px; /* Increased from 600px, adjust as needed */
  width: 100%;
}

.virtual-try-on img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  background-color: transparent; /* Ensure the image background is transparent */
}

.virtual-try-on p {
  text-align: center;
  margin-top: 1rem;
}

.api-example-section {
  
  width: 100%;
  max-width: 800px; /* Constrain the width */
  margin: 0 auto; /* Center the section */
  margin-top: 2rem;
  background-color: #1e2a3a;
  border-radius: 8px;
  padding: 1.5rem;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.api-example-section h3 {
  color: #e6e6e6;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-align: center; /* Center the heading */
}

.code-snippet {
  background-color: #233040;
  color: #d4d4d4;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: 12px;
  line-height: 1.4;
  white-space: pre-wrap; /* Preserve whitespace and wrap text */
  word-break: break-word; /* Break long words to prevent overflow */
}

.code-snippet .string { color: #9cdcfe; }
.code-snippet .number { color: #b5cea8; }
.code-snippet .boolean { color: #569cd6; }
.code-snippet .null { color: #569cd6; }
.code-snippet .key { color: #4ec9b0; }

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  h1 {
    font-size: 2.8rem; /* Adjust for smaller screens */
  }

  p {
    font-size: 1.1rem;
  }

  .primary-button {
    width: 50%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2.2rem; /* Further adjust for very small screens */
  }

  .api-example-section {
    padding: 1rem;
  }

  .code-snippet {
    font-size: 10px;
  }
}

