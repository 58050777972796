.blog-article {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    line-height: 1.6;
  }
  
  .blog-article h1 {
    font-size: 2.5rem;
    color: #1e2a3a;
    margin-bottom: 1rem;
  }
  
  .article-date {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .article-intro {
    font-style: italic;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .blog-article p {
    margin-bottom: 1rem;
  }
  
  .blog-article ul, .blog-article ol {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    padding-left: 2rem;

  }
  
  .feature-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 2rem;
  }

  .feature-list li {
    margin-bottom: 0.8rem; /* Increased spacing between list items */
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.4; /* Decreased line spacing within list items */
    font-size: 1.1rem;
  }

  .feature-list li:last-child {
    margin-bottom: 0; /* Remove margin from the last item */
  }

  .feature-list li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #3498db; /* You can change this color to match your design */
  }

  .feature-list li strong {
    font-weight: 700;
    color: #2c3e50; /* You can change this color to match your design */
  }

  /* Add more styles as needed */

  .blog-article a {
    color: #3498db; /* A distinct color for links */
    text-decoration: none; /* Removes the default underline */
    border-bottom: 1px solid #3498db; /* Adds a subtle underline */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }

  .blog-article a:hover {
    color: #2980b9; /* Darker shade when hovered */
    border-bottom: 2px solid #2980b9; /* Thicker underline on hover */
  }

  .blog-article a:active {
    color: #1f618d; /* Even darker when clicked */
  }

  /* Optional: Add a small icon to indicate external links */
  .blog-article a[target="_blank"]::after {
    content: " \2197"; /* Unicode for north-east arrow */
    font-size: 0.8em;
    vertical-align: super;
  }

  .article-image {
    width: 50%;  /* Reduce to 50% of its container width */
    max-width: 400px;  /* Set a maximum width */
    height: auto;
    margin: 2rem auto;
    display: block;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .blog-article h2 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #2c3e50;
    margin-top: 1rem;
    margin-bottom: 0rem;
    padding-bottom: 0.5rem;
    /* border-bottom: 2px solid #3498db; */
  }

  /* Specific styling for the sections you mentioned */
  .blog-article h2:contains("How Multi-Brand Platforms Benefit Businesses"),
  .blog-article h2:contains("Personalization & Customer Experience"),
  .blog-article h2:contains("Leveraging Data & APIs") {
    color: #2980b9;
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .blog-article {
      padding: 1rem;
    }

    .blog-article h1 {
      font-size: 2rem;
    }

    .blog-article h2 {
      font-size: 1.5rem;
    }

    .article-image {
      width: 100%;
      max-width: 100%;
    }

    .feature-list li {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    .blog-article {
      padding: 0.5rem;
    }

    .blog-article h1 {
      font-size: 1.8rem;
    }

    .blog-article h2 {
      font-size: 1.3rem;
    }
  }